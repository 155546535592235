<template>
  <div
    :class="
      request.status === 'waiting' || (reprint && request.status === 'cooking')
        ? ''
        : 'no-print'
    "
  >
    <v-card
      :color="$helper.setBgColor(request.status)"
      @click="
        !request.isClosedOrder && requestType === 'cooking'
          ? openUpdateRequest(true)
          : ''
      "
    >
      <v-card-actions>
        <BaseIconButton
          class="no-print"
          v-if="!request.isClosedOrder && request.status !== 'cancelled'"
          icon-name="delete"
          @action="openCancelRequest"
        />
        <v-spacer />
        <span class="text-caption grey--text text--darken-2 print-text">
          {{ $helper.formattedDate(request.createdAt) }} -
          {{ request.submittedBy }}</span
        >
        <v-spacer />
        <BaseIconButton
          class="no-print"
          v-if="!request.isClosedOrder && request.status !== 'cancelled'"
          icon-name="pencil"
          @action="openUpdateRequest(false)"
        />
      </v-card-actions>
      <v-card-text
        class="text-center text-h6 black--text text-capitalize py-0 print-text--main"
        >{{ requestDetails }}</v-card-text
      >
      <v-card-subtitle
        v-if="container !== 'order-dialog'"
        class="text-center text-subtitle-1 py-0 print-text--details"
        >{{ customerDetails }}
      </v-card-subtitle>
      <v-card-text
        v-if="request.note"
        class="d-flex font-weight-medium pt-2 pb-0 print-text--details"
      >
        <span class="mr-2 flex-shrink-0 print-margin"
          >{{ $t('common.note') }}:</span
        >
        <span class="font-italic">{{ request.note }}</span>
      </v-card-text>
      <v-card-actions v-if="showBottomAction" class="no-print">
        <v-spacer />
        <BaseIconButton
          :icon-name="subActionIcon"
          @action="updateRequestStatus"
        />
      </v-card-actions>
      <div v-else class="pb-2" />
    </v-card>
    <div v-if="request.pageBreak" style="page-break-after: always;"></div>
  </div>
</template>

<script>
import { displayErrorMessage, updateRequest, displayQuantity } from '@/utils'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'KitchenRequestListItem',

  props: {
    request: Object,
    container: {
      validator: function(value) {
        return ['order-dialog', 'kitchen', 'print'].includes(value)
      },
      default: 'kitchen'
    },
    reprint: Boolean
  },

  computed: {
    requestType() {
      const { status } = this.request
      if (['waiting', 'cooking'].includes(status)) {
        return 'cooking'
      } else if (['ready', 'delivered'].includes(status)) {
        return 'serving'
      } else {
        return 'cancelled'
      }
    },

    subActionIcon() {
      const { status } = this.request
      if (status === 'waiting') {
        return 'cook'
      } else if (status === 'cooking') {
        return 'cooking'
      } else if (status === 'ready') {
        return 'delivering'
      } else if (status === 'delivered') {
        return 'delivered'
      } else {
        return ''
      }
    },

    requestDetails() {
      const { quantity, inKilogram, viName, price } = this.request
      const displayQt = displayQuantity(quantity, inKilogram)
      return `${displayQt} ${viName} - ${price.toLocaleString()}`
    },

    customerDetails() {
      const { tableName, customerName } = this.request
      return `${tableName} - ${customerName}`
    },

    showBottomAction() {
      return (
        !this.request.isClosedOrder &&
        ['cooking', 'serving'].includes(this.requestType)
      )
    }
  },

  methods: {
    openUpdateRequest(complete) {
      this.$emit('openUpdateRequest', {
        data: this.request,
        complete
      })
    },

    openCancelRequest() {
      const item = {
        type: 'cancelRequest',
        data: this.request,
        confirmTitle: 'kitchen-request.cancel-title',
        confirmContent: this.$t('kitchen-request.cancel-text', {
          req: `${this.request.quantity} ${this.request.viName}`
        })
      }
      this.$mitt.emit('openConfirmDialog', item)
    },

    updateRequestStatus() {
      const cloneReq = cloneDeep(this.request),
        originalReq = cloneDeep(this.request)
      if (this.request.status === 'waiting') {
        cloneReq.status = 'cooking'
      } else if (this.request.status === 'cooking') {
        cloneReq.status = 'waiting'
      } else if (this.request.status === 'ready') {
        cloneReq.status = 'delivered'
      } else if (this.request.status === 'delivered') {
        cloneReq.status = 'ready'
      }
      this.$emit('update:request', cloneReq)
      updateRequest(cloneReq, 'editRequest')
        .then()
        .catch(err => {
          this.$emit('update:request', originalReq)
          displayErrorMessage(err)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@media print {
  .print-text {
    color: rgb(0, 0, 0) !important;
    &--main {
      @extend .print-text;
      font-size: 1.5rem;
      font-weight: 700;
      padding-bottom: 0px;
    }
    &--details {
      @extend .print-text;
      font-size: 1.25rem;
      font-weight: 500;
    }
  }

  .print-margin {
    margin-right: 8px;
  }
}
</style>
