<template>
  <div ref="requestList">
    <BaseList
      type="request"
      :noData="requests && requestCount === 0"
      :isLoading="requests === null"
      noDataText="kitchen-request.no-requests-found"
      :pagination="false"
      :searchField="false"
    >
      <v-row v-if="container !== 'print'" class="pb-2">
        <v-col
          v-for="(req, index) in requests"
          :key="index"
          cols="12"
          sm="6"
          :md="fullScreen ? 4 : false"
          :lg="fullScreen && containerSize > 1000 ? 3 : false"
        >
          <KitchenRequestListItem
            :request="req"
            @update:request="payload => requests.splice(index, 1, payload)"
            :container="container"
            @openUpdateRequest="openUpdateRequest"
          />
        </v-col>
      </v-row>
      <div v-else>
        <div v-for="(req, index) in requests" :key="index">
          <KitchenRequestListItem
            :request="req"
            :container="container"
            :reprint="reprint"
            @update:request="payload => requests.splice(index, 1, payload)"
            @openUpdateRequest="openUpdateRequest"
          />
        </div>
      </div>
      <div ref="endRequestList"></div
    ></BaseList>
    <UpdateRequest
      :dialog.sync="updateRequestDialog"
      :title="updateRequestTitle"
      :complete="completeRequest"
      :request="selectedRequest"
      :container="container"
    />
  </div>
</template>

<script>
import KitchenRequestService from '@/services/modules/KitchenRequestService'
import KitchenRequestListItem from '@/components/kitchen-requests/KitchenRequestListItem'
import { displayErrorMessage, makePrint } from '@/utils'
import OrderService from '@/services/modules/OrderService'
import findLastIndex from 'lodash/findLastIndex'

const UpdateRequest = () =>
  import('@/components/kitchen-requests/UpdateRequest')

export default {
  name: 'KitchenRequestList',

  components: {
    KitchenRequestListItem,
    UpdateRequest
  },

  props: {
    container: {
      validator: function(value) {
        return ['order-dialog', 'kitchen', 'print'].includes(value)
      },
      default: 'kitchen'
    },
    fullScreen: {
      type: Boolean,
      default: true
    },
    type: String,
    orderId: String,
    printReady: {
      type: Boolean,
      default: false
    },
    reprint: Boolean
  },

  data: () => ({
    requests: null,
    selectedRequest: {},
    completeRequest: false,
    updateRequestDialog: false,
    updateRequestTitle: null,
    containerSize: 0
  }),

  computed: {
    requestCount() {
      return this.requests.length
    },

    hasWaiting() {
      let hasWaiting, hasCooking
      if (this.requests) {
        hasWaiting = this.requests.some(request => request.status === 'waiting')
        hasCooking = this.requests.some(request => request.status === 'cooking')
      }
      if (hasWaiting) {
        return true
      } else if (hasCooking) {
        return null
      } else {
        return false
      }
    }
  },

  mounted() {
    if (this.container === 'kitchen') {
      this.fetchRequests(this.type)
    } else {
      this.getOrder()
    }
    if (this.container !== 'print') {
      this.getContainerSize()
    }
    this.$mitt.on('editRequest', this.editRequest)
    this.$mitt.on('completeRequest', this.updateRequestStatus)
    this.$mitt.on('cancelRequest', this.updateRequestStatus)
    this.$mitt.on('toggleDrawer', this.changeView)
    this.$mitt.on('addRequests', this.addRequests)
  },
  beforeDestroy() {
    this.$mitt.off('editRequest', this.editRequest)
    this.$mitt.off('completeRequest', this.updateRequestStatus)
    this.$mitt.off('cancelRequest', this.updateRequestStatus)
    this.$mitt.off('toggleDrawer', this.changeView)
    this.$mitt.off('addRequests', this.addRequests)
  },

  watch: {
    printReady(val) {
      if (val) {
        this.$nextTick().then(() => {
          const printedOrder = this.$refs.requestList.innerHTML
          makePrint(printedOrder)
          OrderService.updatePrintedOrder(this.orderId)
            .then(() => {
              this.$socket.client.emit('update-request')
              this.$notify(this.$t('order.message-print-requests'))
            })
            .catch(err => {
              displayErrorMessage(err)
            })
            .finally(() => {
              this.$emit('readyToPrint', false)
            })
        })
      }
    },

    hasWaiting(val) {
      if (this.container === 'order-dialog') {
        this.$mitt.emit('updateHasWaiting', {
          orderId: this.orderId,
          hasWaiting: val
        })
      }
    },

    requests(val) {
      if (val) {
        this.$emit('loadRequests', val)
      }
    }
  },

  methods: {
    getContainerSize() {
      this.containerSize = this.$refs.requestList.clientWidth
    },

    changeView() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        this.getContainerSize()
      }
    },

    fetchRequests(type) {
      KitchenRequestService.getList(type)
        .then(res => {
          this.requests = res.data.docs
        })
        .catch(err => {
          displayErrorMessage(err)
        })
    },

    getOrder() {
      OrderService.getOrder(this.orderId)
        .then(res => {
          const order = res.data
          const { customerName, tableName, requests } = order
          requests.forEach(request => {
            request.customerName = customerName
            request.tableName = tableName
          })
          this.requests = requests
          if (this.container === 'print') {
            this.requests.sort((a, b) => b.fromKitchen - a.fromKitchen)
            const lastIndexFirstSegment = findLastIndex(
              this.requests,
              'fromKitchen'
            )
            if (lastIndexFirstSegment > -1) {
              this.requests[lastIndexFirstSegment].pageBreak = true
            }
          }
          this.$emit('readyToPrint', true)
        })
        .catch(err => displayErrorMessage(err))
    },

    addRequests(additionalRequests) {
      this.requests = [...this.requests, ...additionalRequests]
    },

    openUpdateRequest(request) {
      if (request.complete) {
        this.completeRequest = true
        this.updateRequestTitle = 'kitchen-request.complete-dialog-title'
      } else {
        this.completeRequest = false
        this.updateRequestTitle = 'kitchen-request.edit-dialog-title'
      }
      this.selectedRequest = request.data
      this.updateRequestDialog = true
    },

    editRequest(request) {
      const index = this.requests.findIndex(x => x._id === request._id)
      if (
        this.container === 'order-dialog' &&
        request.orderId !== this.orderId
      ) {
        this.requests.splice(index, 1)
      } else {
        this.requests.splice(index, 1, request)
      }
      this.$emit('update:list', this.requests)
    },

    updateRequestStatus(request) {
      const index = this.requests.findIndex(x => x._id === request._id)
      if (this.container === 'order-dialog') {
        this.requests.splice(index, 1, request)
      } else {
        this.requests.splice(index, 1)
      }
      this.$emit('update:list', this.requests)
    }
  },

  sockets: {
    refreshList() {
      if (this.container === 'kitchen') {
        this.fetchRequests(this.type)
      }
    }
  }
}
</script>

<style></style>
